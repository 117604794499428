import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import BlocksRenderer from "../components/blocks-renderer"
import Seo from "../components/seo"

const IssuePage = ({ data }) => {
  const issue = data.strapiIssue

  const seo = {
    metaTitle: issue.title,
    metaDescription: issue.description,
    shareImage: issue.cover,
  }

  return (
    <Layout as="issue">
      <Seo seo={seo} />
      <header className="container max-w-4xl py-8">
        <h1 className="text-6xl font-bold text-neutral-700">{issue.title}</h1>
        <p className="mt-4 text-2xl text-neutral-500">{issue.description}</p>
        <GatsbyImage
          image={getImage(issue?.cover?.localFile)}
          alt={issue?.cover?.alternativeText}
          className="mt-6"
        />
      </header>
      <main className="mt-8">
        <BlocksRenderer blocks={issue.blocks || []} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiIssue(slug: { eq: $slug }) {
      id
      slug
      title
      description
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default IssuePage
